import { Application, Controller } from "stimulus"

export default class AttendancePickerController extends Controller {
  static targets = ["adultsSelector", "continueButton"]

  connect() {
  }

  backToSearchWithSuggestions() {
    window.modal_bsht_attendance_picker.desactivate();
    window.modal_bsht_search_suggestions.activate();
    window.modal_bsht_search_suggestions.fullScreen();
  }

  continue() {
    setTimeout(() => {
      window.modal_bsht_attendance_picker.desactivate();
      window.modal_bsht_date_range_picker.activate();
      window.modal_bsht_date_range_picker.fullScreen();
    }, 0);
  }
}

const application = Application.start()
application.register("attendance-picker", AttendancePickerController)