import { Application, Controller } from "stimulus"
import Mailcheck from "mailcheck"

export default class EmailInputController extends Controller {
  static targets = ["input", "suggestion"]
  static values = { suggestion: String, suggestionTranslation: String }

  check() {
    Mailcheck.defaultTopLevelDomains.push('cat')
    Mailcheck.run({
      email: this.inputTarget.value,
      suggested: suggestion => {
        this.suggestionValue = suggestion.full
      },
      empty: () => {
        this.suggestionValue = undefined
      }
    })
  }

  acceptSuggestion(event) {
    event.preventDefault()

    this.inputTarget.value = this.suggestionValue
    this.suggestionValue = undefined
  }

  // Private

  suggestionValueChanged() {
    this.hideSuggestion()

    if (this.hasSuggestionValue) {
      this.showSuggestion()
    }
  }

  showSuggestion(suggestion) {
    this.inputTarget.classList.add("is-probably-invalid")
    this.element.insertAdjacentHTML("beforeend", `
      <div id="emailSuggestion" class="text-end mb-3" data-email-input-target="suggestion">
        ${this.suggestionTranslationValue}

        <a href="#" data-action="email-input#acceptSuggestion">${this.suggestionValue}</a>?
      </div>
    `)
  }

  hideSuggestion() {
    if (this.hasSuggestionTarget) {
      this.inputTarget.classList.remove("is-probably-invalid")
      this.suggestionTarget.remove()
    }
  }
}

const application = Application.start()
application.register("email-input", EmailInputController)
