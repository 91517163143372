import { Application } from "stimulus"
import { useDebounce } from "stimulus-use"
import SearchController from "./search_controller"


export default class SearchWithSuggestionsDateController extends SearchController {
  static debounces = ['input']
  static targets = ['backPlaceButton', 'crossDatePickerButton', 'backPlaceLabel', 'searchInput']
  static values = { withDateRangePicker: {type: Boolean, default: true} }

  connect() {
    useDebounce(this, {wait: 350});
    window.search_with_suggestions_date = this;
  }

  startProcess() {
    document.activeElement.blur();
    this.openSearchSuggestionsFullScreen();
    if(!window.isMobile()) {
      setTimeout(() => {
        this.searchInputTarget.focus();
        this.searchInputTarget.click();
      }, 300);
    } else {
      this.loadGoogleServices();
    }
  }

  openSearchSuggestionsFullScreen() {
    this.resetResultList();
    window.modal_bsht_search_suggestions.activate();
    window.modal_bsht_search_suggestions.fullScreen();
  }

  resetResultList() {
    this.resultListTarget.classList.remove("d-block")
    this.resultListTarget.classList.add("d-none")
    this.googlePlaceIdInputTarget.value = this.placeIdValue;
    this.featuredPlacesSuggestedListTarget.classList.remove("d-none");
    this.resetInputSearch();
  }

  resetInputSearch() {
    document.getElementById("input-search-with-suggestions").value = '';
    document.getElementById("input-search-with-suggestions-delete").classList.add("d-none");
  }

  applyPlaceSearch(e) {
    const placeId = e.params.placeid;
    const description = e.params.description;
    this.addPlaceResultParams(placeId, description);
    this.applySearch(e, description);
  }

  applySearch(e, description) {
    if (this.withDateRangePickerValue) {
      e.preventDefault();
      this.changeToAttendancePicker(description);
    }else{
      window.modal_bsht_search_suggestions.desactivate();
      setTimeout(() => { this.submitForm(); }, 300);
    }
  }

  changeToAttendancePicker(description) {
    this.backPlaceLabelTarget.textContent = description.split(',')[0];
    this.displayBackArrowButton();
    window.modal_bsht_search_suggestions.desactivate();
    window.modal_bsht_attendance_picker.activate();
  }

  displayBackArrowButton() {
    this.crossDatePickerButtonTarget.classList.add('d-none');
    this.crossDatePickerButtonTarget.classList.remove('flex-center');
    this.backPlaceButtonTarget.classList.remove('d-none');
    this.backPlaceButtonTarget.classList.add('flex-center');
  }

  displayCrossButton() {
    this.crossDatePickerButtonTarget.classList.remove('d-none');
    this.crossDatePickerButtonTarget.classList.add('flex-center');
    this.backPlaceButtonTarget.classList.add('d-none');
    this.backPlaceButtonTarget.classList.remove('flex-center');
  }

}

const application = Application.start()
application.register("search-with-suggestions-date", SearchWithSuggestionsDateController)
