import { Application, Controller } from "stimulus"
import { useDebounce } from "stimulus-use"

export default class SearchController extends Controller {
  static debounces = ['input']

  static targets = [
    "searchInput", "resultList", "resultForm",
    "googlePlaceIdInput", "googlePlaceNameInput", "latitudeInput", "longitudeInput",
    "featuredPlacesSuggestedList"
  ]

  static values = { withStreets: Boolean, noResultsTranslation: String, 
                    fromBottomSheet: {type: Boolean, default: false},
                    placeId: {type: String, default: ""},
                    prefixTranslation: String,
                    markerIcon: {type: String, default: ""},
                  }

  service;

  connect() {
    useDebounce(this, {wait: 350});

    this.selectedIndexSuggestion = -1

    document.body.addEventListener("click", (e) => {
      if (!this.resultListTarget.classList.contains("d-none") && !this.resultListTarget.contains(e.target) && !this.searchInputTarget.contains(e.target) && !this.isFromSearchPlaceFunnel()) {
        this.resultListTarget.classList.add("d-none")
        this.openSelectedResult(new Event("click"))
      }
    })

    if (!this.searchInputTarget.value && this.googlePlaceIdInputTarget.value) {
      this.loadGoogleJs();
      setTimeout(() => {
        this.service = new google.maps.places.AutocompleteService();
        this.loadGeocoder();
        this.inverseSearch(this.googlePlaceIdInputTarget.value)
      }, 1000)
    }
  }

  loadGoogleJs() {
    if (document.getElementById("googleMapsScript") == null) {
      let googleMapsScript = document.createElement("script");
      googleMapsScript.setAttribute("id", "googleMapsScript");
      googleMapsScript.setAttribute("src", `https://maps.googleapis.com/maps/api/js?callback=initMap&key=AIzaSyDCPYm8Q9HKhJzi-bkEohBEZoP4vp3GVD8&libraries=geometry,places&language=${gon.locale}`);
      document.head.appendChild(googleMapsScript);
    }
  }

  loadGoogleServices() {
    try {
      this.loadGoogleJs();
      setTimeout(() => {
        this.service = new google.maps.places.AutocompleteService();
      }, 750)
    } catch (error) {
      console.log(error);
    }
  }

  loadGeocoder() {
    if (!window.geocoder) { window.geocoder = new google.maps.Geocoder() }
  }

  click() {
    this.loadGoogleServices();
    setTimeout(() => {
      this.selectedIndexSuggestion = -1
      if (this.searchInputTarget.value != "") {
        this.getGooglePlacePredictions(this.searchInputTarget.value)
        this.resultListTarget.classList.add("d-block")
        this.resultListTarget.classList.remove("d-none")
        if (this.isFromSearchPlaceFunnel()) { this.featuredPlacesSuggestedListTarget.classList.add("d-none"); }
      }else {
        this.resultListTarget.classList.remove("d-block")
        this.resultListTarget.classList.add("d-none")
        if (this.isFromSearchPlaceFunnel()) { this.featuredPlacesSuggestedListTarget.classList.remove("d-none"); }
      }
    }, 750)
  }

  input() {
    try {
      this.selectedIndexSuggestion = -1
      window.searchQuery = this.searchInputTarget.value
      if (this.searchInputTarget.value == "") {
        this.resultListTarget.classList.remove("d-block")
        this.resultListTarget.classList.add("d-none")
        this.googlePlaceIdInputTarget.value = this.placeIdValue;
        if (this.isFromSearchPlaceFunnel()) { this.featuredPlacesSuggestedListTarget.classList.remove("d-none"); }
      } else {
        this.resultListTarget.classList.remove("d-none")
        this.resultListTarget.classList.add("d-block")
        if (this.isFromSearchPlaceFunnel()) { this.featuredPlacesSuggestedListTarget.classList.add("d-none"); }
        this.getGooglePlacePredictions(this.searchInputTarget.value)
      }
    } catch (error) {
      console.log(error);
    }
  }

  keydown(event) {
    if (event.key == "Enter") {
      this.openSelectedResult(event)
    } else if (event.key == "ArrowUp") {
      this.moveSelectionUp()
    } else if (event.key == "ArrowDown") {
      this.moveSelectionDown()
    }
  }

  openSelectedResult(event) {
    event.preventDefault()
    const selectedResult = document.querySelector(".tt-cursor")
    if (selectedResult) {
      selectedResult.click()
    } else if (this.resultListTarget.children.length > 0) {
      this.resultListTarget.children[0].click()
    } else {
      this.resultListTarget.classList.remove("d-block")
    }
  }


  // Private

  getGooglePlacePredictions(query) {
    const options = {
      input: query,
      language: gon.locale,
      ...(!this.withStreetsValue && { types: ["locality", "sublocality", "administrative_area_level_3"] })
    }
    this.service.getPlacePredictions(options, this.displayResults.bind(this))
  }

  moveSelectionUp() {
    const predictionsCount = this.resultListTarget.querySelectorAll("li").length;
    if (predictionsCount != 0) {
      if (this.selectedIndexSuggestion > predictionsCount-1) {
        this.resultListTarget.children[this.selectedIndexSuggestion-1].classList.add("tt-cursor")
        this.searchInputTarget.value = document.querySelector(".tt-cursor").textContent
        this.selectedIndexSuggestion--
      } else if (this.selectedIndexSuggestion == 0) {
        this.resultListTarget.children[this.selectedIndexSuggestion].classList.remove("tt-cursor")
        this.searchInputTarget.value = searchQuery
        this.selectedIndexSuggestion = predictionsCount
      } else if (this.resultListTarget.children[this.selectedIndexSuggestion-1]!=null) {
        this.resultListTarget.children[this.selectedIndexSuggestion].classList.remove("tt-cursor")
        this.resultListTarget.children[this.selectedIndexSuggestion-1].classList.add("tt-cursor")
        this.searchInputTarget.value = document.querySelector(".tt-cursor").textContent
        this.selectedIndexSuggestion--
      }
    }
  }

  moveSelectionDown() {
    const predictionsCount = this.resultListTarget.querySelectorAll("li").length;
    if (predictionsCount != 0) {
      if (this.selectedIndexSuggestion < 0) {
        this.resultListTarget.children[this.selectedIndexSuggestion+1].classList.add("tt-cursor")
        this.searchInputTarget.value = document.querySelector(".tt-cursor").textContent
        this.selectedIndexSuggestion++
      } else if (this.selectedIndexSuggestion  == predictionsCount-1) {
        this.resultListTarget.children[this.selectedIndexSuggestion].classList.remove("tt-cursor")
        this.searchInputTarget.value = searchQuery
        this.selectedIndexSuggestion = -1
      } else if (this.resultListTarget.children[this.selectedIndexSuggestion+1]!=null) {
        this.resultListTarget.children[this.selectedIndexSuggestion].classList.remove("tt-cursor")
        this.resultListTarget.children[this.selectedIndexSuggestion+1].classList.add("tt-cursor")
        this.searchInputTarget.value = document.querySelector(".tt-cursor").textContent
        this.selectedIndexSuggestion++
      }
    }
  }

  displayResults(predictions, status) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      this.resultListTarget.innerHTML = ""
      predictions.forEach(prediction => this.appendNewResult(prediction))
      this.resultListTarget.classList.add("d-block")
      this.resultListTarget.classList.remove("d-none")
    } else {
      this.noResults()
    }
  }

  appendNewResult(prediction) {
    return this.isFromSearchPlaceFunnel() ? this.buildNewResultSearchPlaceFunnel(prediction) : this.appendNewResultFrom(prediction);
  }

  buildNewResultSearchPlaceFunnel(prediction) {
    let description = prediction.description
    let placeName = description.split(',')[0].toLowerCase()
    let placeNameFormatted = description.split(',')[0]
    let countryName = description.split(',')[1]
    let inputValue = this.searchInputTarget.value
    
    if(inputValue) { inputValue = inputValue[0].toUpperCase() + inputValue.slice(1); }
    
    if (placeName.includes(inputValue)) {
      placeNameFormatted = placeName.replace(new RegExp(inputValue, "g"), "<b>" + inputValue + "</b>")
    }

    const placeSuggestedItem = `
      <div class="featured-places-suggested-item" data-cy="${placeName}" data-action="click->search-with-suggestions-date#applyPlaceSearch"
      data-search-with-suggestions-date-placeId-param="${prediction.place_id}"
      data-search-with-suggestions-date-description-param="${prediction.description}" >
        <div class="featured-places-suggested-item-image-not-found">
          ${this.markerIconValue}
        </div>
        <div class="featured-places-suggested-item-description">
          <div class="featured-places-suggested-item-prefix">
            ${this.prefixTranslationValue}
          </div>
          <div>
            <span class="featured-places-suggested-item-title">
              ${placeNameFormatted},
            </span>
            <span class="featured-places-suggested-item-subtitle">
              ${countryName}
            </span>
          </div>
        </div>
      </div>
    `
    this.resultListTarget.insertAdjacentHTML("beforeend", placeSuggestedItem);
    return this.resultListTarget.lastElementChild;
  }

  appendNewResultFrom(prediction) {
    const li = this.buildNewResultFrom(prediction);
    li.addEventListener("click", () => {
      this.addPlaceResultParams(prediction.place_id, prediction.description);
      this.addGeocodeAddressPromise(prediction.place_id, prediction.description);
    })
  }

  addPlaceResultParams(place_id, description) {
    this.googlePlaceIdInputTarget.value = place_id
    if (this.hasGooglePlaceNameInputTarget) {
      this.googlePlaceNameInputTarget.value = description
    }
  }

  addGeocodeAddressPromise(place_id, description) {
    this.loadGeocoder();
    this.geocodeAddressPromise(place_id).then(coordinates => {  
      if (this.hasLatitudeInputTarget) {
        this.latitudeInputTarget.value = coordinates.latitude
      }
      if (this.hasLongitudeInputTarget) {
        this.longitudeInputTarget.value = coordinates.longitude
      }

      if (this.hasResultFormTarget) {
        this.resultFormTarget.submit()
      } else {
        this.searchInputTarget.value = description
        this.resultListTarget.classList.remove("d-block")
        this.resultListTarget.classList.add("d-none")
      }

      this.googlePlaceIdInputTarget.dispatchEvent(new Event("change"))
    }, error => {
      console.log(error)
    })
  }

  isFromSearchPlaceFunnel() {
    return this.fromBottomSheetValue;
  }

  geocodeAddressPromise(place_id) {
    return new Promise((resolve, reject) => {
      window.geocoder.geocode(
      {
        "placeId": place_id,
      },
      (results, status) => {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          })
        } else {
          reject("Cannot find address")
        }
      }
      )
    })
  }

  buildNewResultFrom(prediction) {
    const li = document.createElement("li")
    let description = prediction.description
    let inputValue = this.searchInputTarget.value

    inputValue = inputValue[0].toUpperCase() + inputValue.slice(1)
    li.className = "suggestion-item tt-suggestion tt-selectable"

    if (description.includes(inputValue)) {
      li.innerHTML += "<span>" + description.replace(new RegExp(inputValue, "g"), "<b>" + inputValue + "</b>") + "</span>"
    } else {
      li.textContent = description
    }

    return this.resultListTarget.appendChild(li)
  }

  noResults() {
    const span = document.createElement("span")
    span.className = "suggestion-item tt-suggestion mx-4"
    span.textContent = this.noResultsTranslationValue
    this.resultListTarget.innerHTML = span.outerHTML
    this.resultListTarget.classList.add("d-block")
    this.resultListTarget.classList.remove("d-none")
  }

  inverseSearch(placeId) {
    window.geocoder.geocode(
      { 'placeId': placeId },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.searchInputTarget.value = results[0].formatted_address
        }
      }
    )
  }

  submitForm() {
    if(this.resultFormTarget.requestSubmit) {
      this.resultFormTarget.requestSubmit();
    }else {
      this.resultFormTarget.dispatchEvent(new CustomEvent('submit', {bubbles: true}))
    }
  }

}

const application = Application.start()
application.register("search", SearchController)
